import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import IconCheck from '~/images/icon/check.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const philosophyBackgroundcheckStyle = css`
  & > .body {
    position: relative;
    z-index: 2;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      right: -40vw;
      bottom: 0;
      left: -40vw;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0NiIgaGVpZ2h0PSI3MTIiIHZpZXdCb3g9IjAgMCAxMDQ2IDcxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzExMzJfMyIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEwNDYiIGhlaWdodD0iNzEyIj4KPGVsbGlwc2Ugb3BhY2l0eT0iMC40IiBjeD0iNTIzIiBjeT0iMzU2IiByeD0iNTIzIiByeT0iMzU2IiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTEzMl8zKSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTEzMl8zKSI+CjxwYXRoIG9wYWNpdHk9IjAuNCIgZD0iTTEwNDYgMzMyLjVDMTA0NiA1NDIuMDkyIDgxMS44NDUgNzEyIDUyMyA3MTJDMjM0LjE1NSA3MTIgMCA1NDIuMDkyIDAgMzMyLjVDMCAxMjIuOTA4IDIzNC4xNTUgLTQ3IDUyMyAtNDdDODExLjg0NSAtNDcgMTA0NiAxMjIuOTA4IDEwNDYgMzMyLjVaIiBmaWxsPSJ1cmwoI3BhaW50MV9saW5lYXJfMTEzMl8zKSIvPgo8L2c+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTEzMl8zIiB4MT0iNTIzIiB5MT0iMzU2IiB4Mj0iNTIyLjU4NCIgeTI9IjcyOS41MjQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzFFNDBDQSIgc3RvcC1vcGFjaXR5PSIwIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwOTFGRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MV9saW5lYXJfMTEzMl8zIiB4MT0iNTIyLjI3NCIgeTE9Ii02MS43NDc0IiB4Mj0iNTIyLjI3NCIgeTI9IjczMC42OCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMUU0MENBIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwOTFGRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
      background-size: contain;
      aspect-ratio: 1046 / 712;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        right: -24px;
        left: -24px;
      }
    }
  }

  & > .body > .title {
    font-size: ${rem(18)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(24)};
    }
  }

  & > .body > .title > .firstline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    column-gap: 12px;
    font-size: ${rem(18)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-bottom: 8px;
      font-size: ${rem(32)};
    }
  }

  & > .body > .title > .firstline > .logo {
    width: 160px;
    height: auto;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: 290px;
    }
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    row-gap: 24px;
    padding-bottom: 48px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      margin-top: 40px;
      padding-bottom: 56px;
      column-gap: 32px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-bottom: 80px;
      column-gap: 48px;
    }
  }

  & > .body > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 8px;
    background: var(--color-background-bright);

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: -32px;
      right: 0;
      left: 0;
      width: 40px;
      height: 40px;
      margin-right: auto;
      margin-left: auto;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMi43Mjc1IDc2QzMyLjcyNzUgNzguMjA5MSAzNC41MTg0IDgwIDM2LjcyNzUgODBINDMuNzI3NUM0NS45MzY3IDgwIDQ3LjcyNzUgNzguMjA5MSA0Ny43Mjc1IDc2TDQ3LjcyNzUgNDcuNzI3M0w3NiA0Ny43MjczQzc4LjIwOTEgNDcuNzI3MyA4MCA0NS45MzY0IDgwIDQzLjcyNzNWMzYuNzI3M0M4MCAzNC41MTgxIDc4LjIwOTEgMzIuNzI3MyA3NiAzMi43MjczTDQ3LjcyNzUgMzIuNzI3M0w0Ny43Mjc1IDRDNDcuNzI3NSAxLjc5MDg2IDQ1LjkzNjcgMCA0My43Mjc1IDBMMzYuNzI3NSAwQzM0LjUxODQgMCAzMi43Mjc1IDEuNzkwODYgMzIuNzI3NSA0TDMyLjcyNzUgMzIuNzI3M0g0QzEuNzkwODYgMzIuNzI3MyAwIDM0LjUxODEgMCAzNi43MjczTDAgNDMuNzI3M0MwIDQ1LjkzNjQgMS43OTA4NiA0Ny43MjczIDQgNDcuNzI3M0gzMi43Mjc1TDMyLjcyNzUgNzZaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNTQzOV80MDY2OCkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl81NDM5XzQwNjY4IiB4MT0iMzkuOTQ0NCIgeTE9Ii0xLjU1NDQiIHgyPSIzOS45NDQ0IiB5Mj0iODEuOTY4OSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMUU0MENBIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwOTFGRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
      background-size: contain;
      pointer-events: none;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        top: 0;
        bottom: 0;
        left: -44px;
        width: 56px;
        height: 56px;
        margin: auto 0;
      }

      @media screen and (${STYLE.MEDIA.PC}) {
        left: -64px;
        width: 80px;
        height: 80px;
      }
    }
  }

  & > .body > .items > .item > .title {
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    background: var(--color-background-key);
    color: var(--color-text-bright);
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(26)};
    }
  }

  & > .body > .items > .item > .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 32px 48px;
    }
  }

  & > .body > .items > .item > .body > .text {
    flex-grow: 1;
    font-size: ${rem(16)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(18)};
    }
  }

  & > .body > .items > .item > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    row-gap: 8px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 24px;
      row-gap: 12px;
    }
  }

  & > .body > .items > .item > .body > .items > .item {
    display: flex;
    position: relative;
    column-gap: 4px;
    font-size: ${rem(14)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(16)};
    }
  }

  & > .body > .items > .item > .body > .items > .item > .icon {
    width: 1em;
    padding-top: 0.05em;
  }

  & > .body > .items > .item > .body > .items > .item > .icon path {
    fill: var(--color-text-key);
  }

  & > .body > .items > .item > .body > .etc {
    position: absolute;
    right: 48px;
    bottom: 32px;
    font-size: ${rem(14)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(16)};
    }
  }

  & > .decoration {
    display: none;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
      position: absolute;
      bottom: 240px;
      left: calc(50% - 740px);
      width: 128px;
      height: 762px;
    }
  }

  & > .decoration > .image {
    display: block;
    width: 100%;
    height: auto;
  }
`

const PhilosophyBackgroundcheckComponent = () => {
  return (
    <div className={philosophyBackgroundcheckStyle}>
      <div className="body">
        <h2 className="title" data-show-scroll="true">
          <span className="firstline">
            <StaticImage className="logo" src="../../images/logo.svg" placeholder="none" alt="ASHIATO" />
            では、
          </span>
          リファレンスチェックと
          <br className="_mobile-inline" />
          バックグラウンドチェックを
          <br />
          合わせてご利用いただくことで、
          <br className="_mobile-inline" />
          より安心の採用を実現します
        </h2>
        <dl className="items">
          <div className="item" data-show-scroll="true">
            <dt className="title">リファレンスチェック</dt>
            <dd className="body">
              <p className="text">
                採用候補者と一緒に働いたことのある上司や同僚へのアンケートにより、客観的視点から貴社とのフィット度合いを可視化
              </p>
              <ul className="items">
                <li className="item">
                  <IconCheck className="icon" />
                  実際の働きぶり/人柄
                </li>
                <li className="item">
                  <IconCheck className="icon" />
                  業務上の強み/弱み
                </li>
                <li className="item">
                  <IconCheck className="icon" />
                  周囲との関わり方
                </li>
              </ul>
              <p className="etc">etc...</p>
            </dd>
          </div>
          <div className="item" data-show-scroll="true">
            <dt className="title">バックグラウンドチェック</dt>
            <dd className="body">
              <p className="text">
                専門調査員による調査、公的データベースやWeb検索から、ガバナンス上の問題/懸念点を発見
              </p>
              <ul className="items">
                <li className="item">
                  <IconCheck className="icon" />
                  犯罪/逮捕歴
                </li>
                <li className="item">
                  <IconCheck className="icon" />
                  SNSでの問題投稿
                </li>
                <li className="item">
                  <IconCheck className="icon" />
                  学歴/経歴詐称
                </li>
              </ul>
              <p className="etc">etc...</p>
            </dd>
          </div>
        </dl>
      </div>
      <Parallax className="decoration" speed={5}>
        <StaticImage className="image" src="../../images/logo-text.png" loading="lazy" placeholder="none" alt="" />
      </Parallax>
    </div>
  )
}

export default PhilosophyBackgroundcheckComponent
